import React from "react"
import EmailInput from "./email-input"
import cn from "classnames"
import AnimationComponent from "../AnimationComponent"
const EmailUs = ({
  title = "Let’s Work Together",
  highlighted,
  description = "We’re always on the lookout for meaningful collaborations. Get in touch for more information, a demo, or potential partnerships.",
  note = "We will never spam you, promise!",
}) => {
  return (
    <section className={cn("  relative  shadow-lg ")}>
      <div className=" bg-[#001C54] w-full h-full">
        <div className="text-center flex flex-col items-center justify-center shadow-lg border-t border-gray-50 lg:w-10/12 mx-auto bg-white py-20  lg:relative  bottom-10">
          {title && (
            <h2 className="text-[#002369]  text-3xl lg:text-[42px] mb-10">
              <AnimationComponent animationType="slideDown" duration={0.6}>
                {title}
              </AnimationComponent>
            </h2>
          )}
          <div className="w-11/12 lg:w-6/12 mx-auto">
            {description && (
              <p className="text-[#1D263A] mb-14 opacity-70 leading-8">
                <AnimationComponent animationType="slideDown" duration={1}>
                  {description}
                </AnimationComponent>
              </p>
            )}
            <AnimationComponent animationType="slideDown" duration={1.2}>
              <EmailInput />
            </AnimationComponent>
          </div>
          {note && (
            <AnimationComponent
              animationType="fade"
              duration={1.3}
              delay={0.3}
              className="flex items-baseline mt-9"
            >
              <svg
                width="15"
                height="15"
                viewBox="0 0 15 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.875 6.875H3.125C2.43464 6.875 1.875 7.43464 1.875 8.125V12.5C1.875 13.1904 2.43464 13.75 3.125 13.75H11.875C12.5654 13.75 13.125 13.1904 13.125 12.5V8.125C13.125 7.43464 12.5654 6.875 11.875 6.875Z"
                  stroke="#1D263A"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M4.375 6.875V4.375C4.375 3.5462 4.70424 2.75134 5.29029 2.16529C5.87634 1.57924 6.6712 1.25 7.5 1.25C8.3288 1.25 9.12366 1.57924 9.70971 2.16529C10.2958 2.75134 10.625 3.5462 10.625 4.375V6.875"
                  stroke="#1D263A"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <p className="ml-2 text-[#1D263A]">{note}</p>
            </AnimationComponent>
          )}
        </div>
      </div>
    </section>
  )
}
export default EmailUs
