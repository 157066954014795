import React, { useContext, useEffect } from "react"
import { navigate } from "gatsby-link"
import { useForm } from "react-hook-form"
import axios from "axios"
import EmailContext from "../../context/EmailContext"
import classNames from "classnames"

const EmailInput = ({ className, inputClassName, buttonClass }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm()
  const [succes, setSucces] = React.useState(false)
  const [error, setError] = React.useState(false)
  const [email, setEmail] = useContext(EmailContext)

  const onSubmit = data => {
    setSucces(false)
    setError(false)
    axios
      .post(`${process.env.GATSBY_API_URL}/newsletter`, data, {
        "Content-Type": "application/json",
      })
      .then(() => {
        setSucces(true)
        setEmail(data)
      })
      .catch(() => {
        setError(true)
      })
      .finally(() => {
        reset()
        setTimeout(() => {
          navigate("/demo")
        }, 1500)
      })
  }

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      autoComplete="off"
      className="w-full"
    >
      <div className={classNames("flex items-center", className)}>
        <label htmlFor="email" className=" flex-1">
          <input
            type="text"
            id="email"
            className={classNames(
              "p-3 md:px-7 md:py-6 text-sm border border-r-0 rounded-[10px] border-[#DFDFDFB2] focus:outline-none w-full",
              inputClassName
            )}
            placeholder="Email@example.com"
            {...register("email", {
              required: true,
              pattern: /^\S+@\S+$/i,
            })}
          />
        </label>
        <button
          className={classNames(
            "bg-secondary text-sm  p-3 md:px-7 md:py-6 text-white uppercase rounded-r-xl relative -left-2 tracking-widest ",
            buttonClass
          )}
        >
          Request a demo
        </button>
      </div>
      {error || succes
        ? null
        : errors.email?.type === "required" && (
            <p className="text-left my-2">Please Enter Your Email </p>
          )}
      {error || succes
        ? null
        : errors.email?.type === "pattern" && (
            <p className="text-left my-2">Enter Valid Email </p>
          )}

      {succes && <p className="text-left  my-2">Thank You!</p>}
      {succes
        ? null
        : error && (
            <p className="text-left  my-2">Sorry something wrong happened.</p>
          )}

      <input type="submit" hidden />
    </form>
  )
}
export default EmailInput
