import React, { uesState, useState } from "react"
const EmailContext = React.createContext()

export const EmailProvider = ({ children }) => {
  const [email, setEmail] = useState("")
  return (
    <EmailContext.Provider value={[email, setEmail]}>
      {children}
    </EmailContext.Provider>
  )
}
export default EmailContext
