import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Header from "./header"
import Footer from "./footer"
import EmailUs from "./EmailUs"
const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    {
      allSanitySiteConfig {
        nodes {
          sitetitle
          header {
            headerLogo {
              alt
              asset {
                url
                _id
              }
            }
            navButton {
              link
              route
              buttonTitle
            }
            navlinks {
              link
              route
              title
              cascadedLinks {
                sectionTitle
                sectionId
              }
            }
          }
          footer {
            footerLogo {
              asset {
                url
                _id
              }
              alt
            }
            footerlinks {
              title
              footerlinks {
                link
                title
                route
              }
            }
          }
        }
      }
    }
  `)
  const footer = data.allSanitySiteConfig.nodes[0].footer || null
  const header = data.allSanitySiteConfig.nodes[0].header || null
  return (
    <div className="font-Poppins  relative overflow-x-hidden ">
      <Header
        data={header || null}
        siteTitle={data.allSanitySiteConfig.nodes[0].sitetitle}
      />
      <main className="mb-auto relative flex-1">{children}</main>
      <Footer data={footer || null}></Footer>
    </div>
  )
}
export default Layout
