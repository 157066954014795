import React, { useState } from "react"
import { Link } from "gatsby"
import Hamburger from "hamburger-react"
import { useMediaQuery } from "react-responsive"
import { motion, AnimatePresence, useViewportScroll } from "framer-motion"
import Logo from "../images/logo.svg"
import WhiteLogo from "../images/white-logo.svg"
import * as NavigationMenu from "@radix-ui/react-navigation-menu"
import { CaretDownIcon } from "@radix-ui/react-icons"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import { useLocation } from "@reach/router"
import { cn } from "../lib/helpers"
const variants = {
  visible: { opacity: 1, y: 0 },
  hidden: {
    opacity: 0,
    y: -25,
  },
}
const Header = ({ data }) => {
  const [isOpen, setOpen] = useState(false)
  const isMobileOrTablet = useMediaQuery({ maxWidth: 1023 })
  const location = useLocation()

  return (
    <header
      className={cn(
        "py-4 lg:py-8 w-full top-0 bg-white",
        (location.pathname === "/" ||
          location.pathname.includes("/about") ||
          location.pathname.includes("/products") ||
          location.pathname.includes("/impact")) &&
          " absolute lg:bg-transparent z-50 text-white"
      )}
    >
      <div className=" w-11/12 xl:w-10/12 mx-auto">
        <div className="flex justify-between items-center">
          <LogoComponent path={location.pathname} />
          {isMobileOrTablet && (
            <Hamburger
              size={32}
              hideOutline={true}
              rounded={true}
              label="Show menu"
              color="#0D456F"
              toggled={isOpen}
              toggle={setOpen}
            />
          )}
          {!isMobileOrTablet && (
            <>
              {/* <NavLinksDesktop links={data.navlinks} /> */}
              <DesktopNavBar links={data.navlinks} />
              <Button
                className={cn(
                  "bg-secondary text-white flex-shrink-0",
                  location.pathname === "/" &&
                    "bg-transparent border border-white text-white hover:bg-secondary hover:text-white hover:border-secondary transition-all "
                )}
                navButton={data.navButton}
              />
            </>
          )}
        </div>
        {isMobileOrTablet && (
          <AnimatePresence initial={false}>
            {isOpen && (
              <motion.nav
                className="mt-3"
                initial="collapsed"
                animate="open"
                exit="collapsed"
                variants={{
                  open: { opacity: 1, height: "auto" },
                  collapsed: { opacity: 0, height: 0 },
                }}
                transition={{ duration: 0.8, ease: [0.04, 0.62, 0.23, 0.98] }}
              >
                <div className=" text-black">
                  <MobileNavBar links={data.navlinks} />
                  <Button
                    navButton={data.navButton}
                    className={cn(
                      "w-full rounded-xl",
                      location.pathname === "/" && "bg-transparent border-white"
                    )}
                  />
                </div>
              </motion.nav>
            )}
          </AnimatePresence>
        )}
      </div>
    </header>
  )
}

export default Header

const LogoComponent = ({ path }) => {
  return (
    <h2>
      <Link to="/">
        {path === "/" ||
        path.includes("/about") ||
        path.includes("/products") ||
        path.includes("/impact") ? (
          <img src={WhiteLogo} alt={"Pylon UMP"} />
        ) : (
          <img src={Logo} alt={"Pylon UMP"} />
        )}
      </Link>
    </h2>
  )
}

const MobileNavBar = ({ links }) => {
  return (
    <ul className="font-light no-underline flex gap-4 z-50  flex-col lg:flex-row list-none justify-between mb-4 lg:mb-0">
      {links.map((navLink, index) => {
        return (
          <li className="z-50" key={index++}>
            <LinkOrRoute {...navLink} />
          </li>
        )
      })}
    </ul>
  )
}

const DesktopNavBar = ({ links }) => {
  return (
    <ul className="font-light no-underline flex gap-4 xl:gap-8 flex-col lg:flex-row list-none justify-between mb-4 lg:mb-0">
      {links.map((navLink, index) => {
        return (
          <li key={index++}>
            <LinkOrRoute {...navLink} />
          </li>
        )
      })}
    </ul>
  )
}

const RouteWithDropDown = ({ route, routeTitle, cascadedLinks }) => {
  return (
    <NavigationMenu.Item className="relative">
      <NavigationMenu.Trigger className=" group flex select-none items-center gap-2 outline-none  ">
        <button className="no-underline font-light flex gap-2 items-center">
          <span>{routeTitle}</span>
        </button>
        <CaretDownIcon
          className="relative top-[1px] transition-transform duration-[250] ease-in group-data-[state=open]:-rotate-180"
          aria-hidden
        />
      </NavigationMenu.Trigger>
      <NavigationMenu.Content className="data-[motion=from-start]:animate-enterFromLeft data-[motion=from-end]:animate-enterFromRight data-[motion=to-start]:animate-exitToLeft data-[motion=to-end]:animate-exitToRight absolute top-0 left-0 z-50">
        <ul className="flex flex-col gap-3 list-none px-5 py-3 w-full rounded-md bg-white h-full border border-gray-100 ">
          {cascadedLinks?.map(link => {
            const { sectionTitle, sectionId } = link
            return (
              <AnchorLink
                key={sectionId}
                to={`${route}/${sectionId}`}
                className=" text-secondary cursor-pointer"
              >
                {sectionTitle}
              </AnchorLink>
            )
          })}
        </ul>
      </NavigationMenu.Content>
      <div className=" absolute top-full left-0 w-screen mt-4 h-40">
        <NavigationMenu.Viewport className="w-full data-[state=open]:animate-scaleIn data-[state=closed]:animate-scaleOut relative h-[var(--radix-navigation-menu-viewport-height)] overflow-hidden transition-[width,_height] duration-300 " />
      </div>
    </NavigationMenu.Item>
  )
}
const LinkOrRoute = ({ link, route, title, cascadedLinks }) => {
  return (
    <>
      {link && (
        <a
          href={link}
          target="_blank"
          rel="noopener noreferrer"
          className="no-underline z-50"
        >
          {title}
        </a>
      )}
      {route && cascadedLinks?.length == 0 ? (
        <Link
          to={route}
          className="no-underline font-light z-50"
          activeClassName=" "
          activeStyle={{ fontWeight: "bold" }}
        >
          <span>{title}</span>
        </Link>
      ) : (
        <NavigationMenu.Root>
          <NavigationMenu.List>
            <RouteWithDropDown
              route={route}
              cascadedLinks={cascadedLinks}
              routeTitle={title}
            />
          </NavigationMenu.List>
        </NavigationMenu.Root>
      )}
    </>
  )
}
const Button = ({ navButton, className }) => {
  const { link, route, buttonTitle } = navButton
  return (
    <>
      {link ? (
        <a
          href={link}
          target="_blank"
          rel="noopener noreferrer"
          className={cn(
            "py-3 px-8 bg-[#96D228] text-white  rounded-3xl text-xs no-underline",
            className
          )}
        >
          {buttonTitle}
        </a>
      ) : (
        <Link
          to={route}
          className={cn(
            "py-3 px-8 bg-[#96D228] text-white  rounded-3xl text-xs no-underline",
            className
          )}
        >
          {buttonTitle}
        </Link>
      )}
    </>
  )
}
