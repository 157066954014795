/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it
import React from "react"
import "./src/styles/global.css"
import Layout from "./src/components/layout"
import { EmailProvider } from "./src/context/EmailContext"

export function wrapPageElement({ element, props }) {
  // props provide same data to Layout as Page element will get
  // including location, data, etc - you don't need to pass it
  return <Layout {...props}>{element}</Layout>
}

export function wrapRootElement({ element }) {
  return <EmailProvider>{element}</EmailProvider>
}
