import React from "react"
import { Link } from "gatsby"
// import { StaticImage } from "gatsby-plugin-image"
const Footer = props => {
  return (
    <footer className=" pt-24 lg:pt-0 pb-14 bg-[#001C54] font-Poppins">
      <div className="w-11/12 lg:w-11/12 mx-auto py-16">
        <div className="grid lg:grid-cols-5 gap-y-4 gap-x-8">
          <div>{Logo}</div>
          {props.data.footerlinks.map((menu, index) => {
            return (
              <div className="flex flex-col" key={index}>
                <h2 className="text-white text-2xl mb-8 ">{menu.title}</h2>
                <ul className="list-none flex flex-col p-0 m-0 ">
                  {menu.footerlinks.map((footerlink, index) => {
                    if (footerlink.link) {
                      return (
                        <li key={index} className="mb-6">
                          <a
                            href={footerlink.link}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="no-underline text-white hover:underline"
                          >
                            {footerlink.title}
                          </a>
                        </li>
                      )
                    }
                    if (footerlink.route) {
                      return (
                        <li key={index} className="mb-6">
                          <Link
                            to={footerlink.route}
                            className="no-underline font-light  text-white hover:underline"
                          >
                            {footerlink.title}
                          </Link>
                        </li>
                      )
                    }
                  })}
                </ul>
              </div>
            )
          })}
        </div>
      </div>
    </footer>
  )
}
export default Footer

const Logo = (
  <svg
    width="136"
    height="36"
    viewBox="0 0 136 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M34.089 13.4629H16.1956C15.6923 13.4629 15.2931 13.8486 15.2931 14.3349V35.1279C15.2931 35.4969 15.5361 35.8322 15.8832 35.9496C15.9873 35.9831 16.0915 35.9999 16.1956 35.9999C16.4559 35.9999 16.7163 35.8825 16.8898 35.6813L34.7832 14.8882C35.0089 14.6199 35.0609 14.2678 34.9047 13.9492C34.7485 13.6641 34.4361 13.4629 34.089 13.4629Z"
      fill="#007EFF"
    />
    <path
      d="M19.1113 0.0480793C18.7468 -0.069301 18.3477 0.0313105 18.1047 0.316377L0.211271 21.1095C-0.014349 21.3778 -0.0664147 21.7299 0.089784 22.0485C0.245983 22.3503 0.558379 22.5516 0.905488 22.5516H18.7989C19.3022 22.5516 19.7014 22.1659 19.7014 21.6796V0.88651C19.7188 0.500832 19.4758 0.182228 19.1113 0.0480793Z"
      fill="#96D228"
    />
    <path
      d="M53.1106 17.2693C52.7634 17.7388 52.2601 18.091 51.6006 18.3257C50.9411 18.5605 50.1601 18.6779 49.2229 18.6779H46.4981V12.4399H49.2229C50.1428 12.4399 50.9411 12.5573 51.6006 12.7753C52.2601 12.9933 52.7634 13.3287 53.1279 13.7814C53.475 14.2342 53.6659 14.8043 53.6659 15.4918C53.6312 16.1961 53.4577 16.7998 53.1106 17.2693ZM53.8221 9.47189C52.5031 8.93529 50.9411 8.66699 49.1015 8.66699H42.2981V27.3472L46.4981 27.3137V22.4005H49.3271C51.1147 22.4005 52.642 22.1322 53.9263 21.5956C55.2106 21.059 56.1998 20.2709 56.894 19.248C57.5883 18.2251 57.9354 16.9507 57.9354 15.4583C57.9354 13.9994 57.5709 12.7585 56.8593 11.7692C56.1478 10.7798 55.1411 10.0253 53.8221 9.47189Z"
      fill="white"
    />
    <path
      d="M110.366 20.3211C110.071 21.0254 109.672 21.6626 109.151 22.1992C108.631 22.7358 108.006 23.155 107.311 23.4568C106.617 23.7587 105.854 23.8928 105.055 23.8928C104.257 23.8928 103.493 23.7419 102.799 23.4568C102.105 23.155 101.48 22.7526 100.959 22.1992C100.439 21.6626 100.022 21.0422 99.7445 20.3211C99.4495 19.6168 99.3107 18.8455 99.3107 18.0238C99.3107 17.1854 99.4495 16.4308 99.7445 15.7265C100.04 15.0222 100.439 14.4018 100.959 13.8652C101.48 13.3286 102.105 12.9094 102.799 12.6075C103.493 12.3057 104.257 12.1716 105.055 12.1716C105.854 12.1716 106.617 12.3225 107.311 12.6075C108.006 12.9094 108.631 13.3286 109.151 13.8652C109.672 14.4018 110.088 15.0222 110.366 15.7265C110.661 16.4308 110.8 17.2021 110.8 18.0238C110.8 18.8455 110.661 19.6001 110.366 20.3211ZM112.24 11.1151C111.321 10.2432 110.245 9.57242 109.03 9.06936C107.815 8.58307 106.478 8.33154 105.055 8.33154C103.632 8.33154 102.313 8.58307 101.098 9.06936C99.866 9.55565 98.8074 10.2432 97.8875 11.1151C96.985 11.9871 96.2735 13.01 95.7528 14.2006C95.2495 15.3911 94.9892 16.6655 94.9892 18.0238C94.9892 19.3988 95.2495 20.6732 95.7528 21.847C96.2561 23.0376 96.9677 24.0605 97.8875 24.9492C98.79 25.8212 99.866 26.5087 101.098 26.995C102.33 27.4813 103.65 27.7161 105.055 27.7161C106.496 27.7161 107.815 27.4813 109.03 26.995C110.245 26.5087 111.321 25.838 112.24 24.9492C113.16 24.0773 113.872 23.0376 114.375 21.847C114.878 20.6565 115.139 19.3821 115.139 18.0238C115.139 16.6488 114.878 15.3744 114.375 14.2006C113.872 13.01 113.16 11.9871 112.24 11.1151Z"
      fill="white"
    />
    <path
      d="M132.043 8.66709V22.4006L124.407 9.74029C124.094 9.22046 123.695 8.85155 123.192 8.61679C122.688 8.38203 122.133 8.26465 121.491 8.26465C120.97 8.26465 120.484 8.38203 120.033 8.61679C119.564 8.85155 119.2 9.18692 118.922 9.62291C118.645 10.0589 118.506 10.5955 118.506 11.1992V27.3306H122.497V13.5132L130.134 26.2574C130.446 26.7604 130.845 27.1293 131.366 27.3473C131.887 27.5821 132.442 27.6995 133.067 27.6995C133.57 27.6995 134.039 27.5821 134.49 27.3641C134.941 27.1461 135.306 26.8107 135.583 26.3748C135.861 25.9388 136 25.4022 136 24.7817V8.65033H132.043V8.66709Z"
      fill="white"
    />
    <path
      d="M84.68 8.66699H80.48V23.8761C80.48 24.9326 80.7751 25.771 81.3478 26.4082C81.9205 27.0454 82.8751 27.3472 84.1941 27.3472H93.7049V23.6078H84.68V8.66699Z"
      fill="white"
    />
    <path
      d="M77.3734 8.66699H72.8263L68.8866 15.844C68.8172 16.0116 68.7478 16.1458 68.6784 16.2464C68.6263 16.347 68.5395 16.3973 68.4527 16.3973C68.3486 16.3973 68.2792 16.347 68.2445 16.2632C68.1924 16.1626 68.123 16.0284 68.0362 15.844L64.0792 8.66699H59.5668L66.3701 20.7236V27.3472H70.5701V20.7572L77.3734 8.66699Z"
      fill="white"
    />
  </svg>
)
