import React, { useEffect, useRef, useState } from "react"
import { motion, useAnimation } from "framer-motion"

const AnimationComponent = ({
  children,
  animationType,
  className,
  duration,
  delay,
  ...props
}) => {
  const controls = useAnimation()
  const sectionRef = useRef(null)
  const [hasAnimated, setHasAnimated] = useState(false)

  useEffect(() => {
    const sectionElement = sectionRef.current

    const observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting && !hasAnimated) {
            controls.start("animate")
            setHasAnimated(true)
          }
        })
      },
      { threshold: 0.5 } // Adjust the threshold as needed
    )

    if (sectionElement) {
      observer.observe(sectionElement)
    }

    return () => {
      if (sectionElement) {
        observer.unobserve(sectionElement)
      }
    }
  }, [controls, hasAnimated])

  let animationVariant

  // Define animation variants based on the animation type prop
  switch (animationType) {
    case "fade":
      animationVariant = {
        initial: { opacity: 0 },
        animate: { opacity: 1 },
        exit: { opacity: 0 },
      }
      break
    case "slideRight":
      animationVariant = {
        initial: { x: -50 },
        animate: { x: 0 },
        exit: { x: -50 },
      }
      break
    case "slideLeft":
      animationVariant = {
        initial: { x: 50 },
        animate: { x: 0 },
        exit: { x: 50 },
      }
      break
    case "slideDown":
      animationVariant = {
        initial: { y: -50 },
        animate: { y: 0 },
        exit: { x: -50 },
      }
      break
    case "slideUp":
      animationVariant = {
        initial: { y: 50 },
        animate: { y: 0 },
        exit: { x: 50 },
      }
      break
    case "scale":
      animationVariant = {
        initial: { scale: 0 },
        animate: { scale: 1 },
        exit: { scale: 0 },
      }
      break
    case "rotate":
      animationVariant = {
        initial: { rotate: 0 },
        animate: { rotate: 360 },
        exit: { rotate: 0 },
      }
      break
    default:
      // Default animation variant
      animationVariant = {
        initial: { opacity: 0 },
        animate: { opacity: 1 },
        exit: { opacity: 0 },
      }
      break
  }

  const transition = {
    duration: duration || 0, // Adjust the duration as needed (in seconds)
    ease: "easeInOut", // Adjust the easing function as needed
    delay: delay || 0,
  }

  return (
    <div
      // ref={sectionRef}
      // initial="initial"
      // animate={controls}
      // exit="exit"
      // variants={animationVariant}
      // transition={transition}
      className={className}
      {...props}
    >
      {children}
    </div>
  )
}

export default AnimationComponent
